import DOMPurify from 'dompurify'
import { useStateMachine } from 'little-state-machine'
import React, { useEffect, useState } from 'react'
import { Button, Container, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { updateToolboxPageData } from '../actions'
import handle from '../assets/handle.png'
import toolbox from '../assets/toolbox.png'
import toolboxBottomHandle from '../assets/toolboxbottom_handle.png'
import toolboxBottom from '../assets/toolboxbottom_tools.png'
import { textToEmoji, ToolboxPageKeys, ToolboxStoreKeys } from '../constants'

const ToolboxContainer = styled.div`
    position: relative;
`

const HandleImage = styled.img`
    width: 100%;
    height: auto;
`

const ToolboxImage = styled.img`
    width: 100%;
    height: auto;
`

const ToolboxColumnsWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
`

const ToolboxColumn = styled.div`
    width: calc(100% / 3);
    height: 100%;
`

const ToolboxRowWrapper = styled.div`
    width: 100%;
    height: ${(props) => props.height};
    padding: 1.75rem;

    & > h5 {
        text-align: center;
        margin: 0;
    }
`

const ToolboxRow = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 0.25rem;
`

const ToolboxItem = styled.div`
    background-color: rgba(255, 255, 255, 0);
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`

// const ToolContainer = styled.div`
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-template-rows: auto 1fr;
//     column-gap: 1rem;
//     width: 100%;
//     align-items: center;

//     & h5 {
//         text-align: center;
//     }

//     & img {
//         width: 100%;
//         height: auto;
//     }

//     & .icon-wrapper {
//         position: relative;
//     }
// `

const gotoItems = [
    ['📏Structure',
        `<p>Structure is how a story is told through layout and length. If your passage is a poem, see the tool “form” instead.
        <ul>
            <li>➡️linear: Events are told in chronological order.</li>
            <li>📈nonlinear: Events are told out of order.</li>
            <li>🖇️repetitive: The writer repeats certain lines, paragraphs, or events in order to emphasize a point.</li>
        </ul>
    </p>`],
    ['📜Form', `<p>Form is the physical structure of a poem, including: the length of the lines, the rhythms, and the system of rhymes and repetition. If your passage is not a poem, see the tool “structure” instead.
        <ul>
            <li>🦋free verse: The poet does not follow rules when formatting the poem.</li>
            <li>🎶rhyming verse: The poet follows a specific rhyme scheme, like alternate rhyme (ABAB) or enclosed rhyme (ABBA).</li>
        </ul>
    </p>`],
    ['🗣️Diction', `
    <p>Diction is the writer’s word choice. Writers choose words based on their appearance, sound, and meaning.
        <ul>
            <li>👔formal: Used in professional settings.</li>
            <li>👖informal: Used in casual, everyday life settings. Also known as colloquial language.</li>
            <li>🕶️slang: Mostly used in speech, texting, and social media to show belonging to a certain group, such as Gen Z.</li>
            <li>‼️repetitive diction (anaphora): Certain words or similar words are used over and over again.</li>
        </ul>
    </p>`],
    ['🖼️Imagery', `<p>The writer uses imagery to paint a picture of their story.
        <ul>
            <li>👀sensory: Uses some or all of the 5 senses of sight, sound, smell, taste, touch.</li>
            <li>🌱natural: Embraces elements of nature.</li>
            <li>🌄pastoral: Imitates rural life.</li>
            <li>🏙️urban: Describes living in a big city.</li>
            <li>🛍️materialistic: Concerned with material things like expensive clothes, jewelry, and cars.</li>
            <li>🧩fragmented: Presented in bits and pieces.</li>
            <li>💞romantic: Captures emotion and intimacy.</li>
            <li>🎠nostalgic: Longs for happy memories from the past.</li>
            <li>🍔food: Includes detailed descriptions of food.</li>
            <li>😭emotional: Includes many descriptions of inner feelings.</li>
        </ul>
    </p>`]
]
const funToolsItems = [
    ['😂Humor', `Writers use humor to build connections with readers. Humor puts readers in a good mood and makes them want to listen to the writer’s ideas more. At the same time, humor also distracts readers from counterarguments. Furthermore, humor helps writers communicate difficult messages to their readers.`],
    ['🤣Satire', 'Satire is both a genre and literary device. Writers use a combination of humor, irony, and exaggeration to criticize human nature.'],
    ['🤦‍♀️Irony', `
    <p>A contrast between expectations & reality. 
        <ul>
            <li>🙊Verbal (Sarcasm): Use words to mock someone.</li>
            <li>🙉Dramatic: Readers know a secret that some characters don’t know.</li>
            <li>🙈Situational: Mocks the circumstances.</li>
        </ul>
    </p>`],
    ['🎎🎮Anachronism', `
    <p>An object or idea that doesn’t belong to the surrounding context or time period. It’s intentionally misplaced for purposes of rhetoric (persuasion), propaganda, comedy, or shock.</p>
    <p>Ex: While the movie <i>Marie Antoinette</i> depicts the 1770s, Converse shoes are included in the wardrobe. This anachronism reveals that Queen Marie is actually a relatable teenager.</p>
    `]
]
const figurativeLangItems = [
    ['🙂☀️Simile',
        `<p>Comparing 2 things using ‘like’ or ‘as.’</p>
        <p>Ex: Your smile is like sunshine.</p>`],
    ['☕Analogy', `
    <p>Using an elaborate explanation to make a point. An analogy can contain simile and metaphor.</p>
    <p>Ex: "Memory is to love what the saucer is to the cup" - Elizabeth Bowen, The House in Paris</p>`],
    ['🙆‍♀️🦄Metaphor',
        `<p>Comparing 2 things without ‘like’ or ‘as.’</p>
        <p>Ex: That girl is a unicorn.</p>
     
        <p>
            A metaphor has 2 parts: tenor & vehicle. The tenor is the root idea that the metaphor starts with, 
            and the vehicle is another concept that helps readers understand the root idea better. In the example above, the tenor is “that girl,” 
            and the vehicle is “unicorn.” We compare “that girl” to a “unicorn” to better understand that she gives off special and magical vibes.
        </p>`],
    ['💬Extended Metaphor',
        `
        <p>
            As its name suggests, an extended metaphor is a metaphor...but extended! It compares 2 unlike things, and this comparison continues for longer than 
            1 sentence. Writers choose to use an extended metaphor to project a specific vision of their topic. An extended metaphor also helps readers grasp difficult 
            concepts and make complex connections that they wouldn’t otherwise make on their own.
        </p>
        <p>Ex: 'Cause baby, you're a firework / Come on, show 'em what you're worth / Make 'em go, "Oh, oh, oh" / As you shoot across the sky” - Katy Perry, “Firework”</p>
        
        <p>
            An extended metaphor has 2 parts: tenor & vehicle. The tenor is the root idea that the metaphor starts with, and the vehicle 
            is another concept that helps readers understand the root idea better. For example, in Katy Perry’s “Firework,” the tenor is “you,” and the vehicle is “firework.” 
            Throughout the song, she compares “you” to a “firework” to help you better understand that you are special and bright.
        </p>`],
    ['🌞Personification', `
        <p>Giving human characteristics to animals, plants, or objects.</p>
        <p>Ex: The sun smiled down at us.</p>`],
    ['🗽Symbol', `
    <p>
        Symbols are words, people, things, colors, locations, or abstract ideas that represent something beyond 
        their literal meaning. They help the writer illustrate their message to readers instead of telling it directly. 
        Symbols also add imagery and set the mood for the passage.
    </p>
    <p>Ex: The Statue of Liberty is a symbol of freedom and democracy.</p>`],
    ['🗽🗽Motif', `
    <p>A motif is a recurring symbol that keeps showing up throughout the story, so it must mean something!</p>
    <p>Ex: Across her albums, Taylor Swift uses the dress motif as a physical representation of her different feelings 
    in different moments of her life (“In my best dress, fearless,” “Today was a fairytale, I wore a dress,” “The girl in the dress cried the whole way home,” etc.).</p>`],
    ['🔴🚫Oxymoron',
        `<p>2 words that contradict each other but make sense when put together.</p>
    <p>Ex: Pretty ugly</p>`],
    ['😯Hyperbole',
        `<p>Making an exaggeration to emphasize a point.</p>
    <p>Ex: I gained 1000 pounds during the quarantine.</p>`]
]
const refToolsItems = [
    ['🎨Allegory', `
    <p>
        <p>Think of a story as a work of art with a hidden meaning. The story’s elements (characters, places, and plotlines) are used to deliver a big message about real-world issues and events.</p>
        <p>Ex: Animal Farm by George Orwell is not just about talking animals; instead, the way the animals take control of the farm is an allegory for the rise of communism in the Russian Revolution.</p>
        <ul>
            <li>🙏biblical: References themes from the Bible.</li>
            <li>🏛️classical: Taken from the classical literature of ancient civilizations like Greek and Rome. Ex: Plato’s <i>Allegory of the Cave</i> (published in the year 375 BC).</li>
            <li>📱modern: Represents events and pop culture moments from modern history.</li>
        </ul>
    </p>`],
    ['😉Allusion', `
    <p>Allusion: An allusion references people, places, events, or other books. It’s up to readers to make the connection. If you understand the reference, it’s like you’re part of a secret smart club😏</p>
    <p>Ex: In her song “Love Story,” Taylor Swift alludes to Shakespeare’s play Romeo and Juliet: “That you were Romeo, you were throwing pebbles / And my daddy said, ‘Stay away from Juliet.’”</p>
    `]
]
const plotDevicesItems = [
    ['🧗‍♀️Cliffhanger', 'Also known as unresolved ending in which not all of characters’ problems are solved. These loose ends can drive readers crazy...unless a sequel is coming.'],
    ['🔮Foreshadowing', `
    <p>Writers use foreshadowing to give an advance hint of what’s to come later on in the story.
        <ul>
            <li>⚠️Concrete (Chekov’s Gun): Author directly states something that they want you to be aware of in the future.</li>
            <li>🧙‍♀️Prominent (Prophecy): A character receives a fortune/prophecy that comes true.</li>
            <li>💥Evocative (Flashback/Flashforward): Goes back/forward in time to reveal important info.</li>
            <li>🌪️Abstract (Symbolic Foreshadowing): A slight hint like the change of weather means bad news is coming.</li>
            <li>🚩Red Herring (Fallacy): Diverts the reader’s attention and throws them off.</li>
        </ul>
    </p>`]
]
const miscToolsItems = [
    ['🤷‍♀️Paradox', 'A paradox is a statement appears to contradict itself. It may seem absurd on the surface, but may contain a deeper truth. Since a paradox expresses ideas out of line with traditional concepts, it allows readers to think more thoroughly and creatively about a topic.'],
    ['🏡Verisimilitude', 'When a scene has the appearance of being true or real, but it is actually just made up. Imagery, anecdotes, and location-specific features all contribute to verisimilitude to make fiction seem more realistic.'],
    ['🔍Vignette', 'Think of a photo you’d keep in a locket. A vignette is a poetic slice-of-life portrait. It allows readers to momentarily step away from the action of the plot and zoom in for a closer examination of a particular character, concept, or place.']
]

const gotoTextSelect = [
    '➡️Linear Structure',
    '📈Non-linear Structure',
    '🖇️Repetitive Structure',
    '🦋Free Verse Form',
    '🎶Rhyming Verse Form',
    '👔Formal Diction',
    '👖Informal Diction',
    '🕶️Slang Diction',
    '‼️Repetitive Diction',
    '👀Sensory Imagery',
    '🌱Natural Imagery',
    '🌄Pastoral Imagery',
    '🏙️Urban Imagery',
    '🛍️Materialistic Imagery',
    '🧩Fragmented Imagery',
    '💞Romantic Imagery',
    '🎠Nostalgic Imagery',
    '🍔Food Imagery',
    '😭Emotional Imagery',
]

const refToolsTextSelect = [
    '🙏Biblical Allegory',
    '🏛️Classical Allegory',
    '📱Modern Allegory',
    '😉Allusion'
]

const funTextSelect = [
    '😂Humor',
    '🤣Satire',
    '🎎🎮Anachronism',
    '🙊Verbal Irony',
    '🙉Dramatic Irony',
    '🙈Situational Irony',
]

const plotTextSelect = [
    '🧗‍♀️Cliffhanger',
    '⚠️Concrete Foreshadowing',
    '🧙‍♀️Prominent Foreshadowing',
    '💥Evocative Foreshadowing',
    '🌪️Abstract Foreshadowing',
    '🚩Red Herring Foreshadowing',
]

const gotoOptions = { label: 'Go-To Tools', options: gotoTextSelect.map((name) => ({ label: name, value: name })) }
const funOptions = { label: 'Fun Tools', options: funTextSelect.map((name) => ({ label: name, value: name })) }
const figurativeOptions = { label: 'Figurative Language', options: figurativeLangItems.map(([name,]) => ({ label: name, value: name })) }
const refOptions = { label: 'Reference Tools', options: refToolsTextSelect.map((name) => ({ label: name, value: name })) }
const plotOptions = { label: 'Plot Devices', options: plotTextSelect.map((name) => ({ label: name, value: name })) }
const miscOptions = { label: 'Misc. Tools', options: miscToolsItems.map(([name,]) => ({ label: name, value: name })) }

const allOptions = [gotoOptions, funOptions, figurativeOptions, refOptions, plotOptions, miscOptions]

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
}

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
)

const removeEmojis = (str) => {
    while (str[0].toUpperCase() === str[0].toLowerCase()) {
        str = str.substring(1)
    }

    return str
}

const Toolbox = ({ next }) => {
    const [currItemTitle, setCurrItemTitle] = useState(null)
    const [currDefText, setCurrDefText] = useState(null)
    const [showDefModal, setShowDefModal] = useState(false)
    const { state, actions } = useStateMachine({ updateToolboxPageData })

    const tool1KeyState = state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool1]
    const tool2KeyState = state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool2]
    const tool3KeyState = state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool3]

    const tool1Restore = tool1KeyState ? textToEmoji[tool1KeyState] : null
    const tool2Restore = tool2KeyState ? textToEmoji[tool2KeyState] : null
    const tool3Restore = tool3KeyState ? textToEmoji[tool3KeyState] : null

    const [tool1, setTool1] = useState(tool1Restore ? { label: tool1Restore, value: tool1Restore } : null)
    const [tool2, setTool2] = useState(tool2Restore ? { label: tool2Restore, value: tool2Restore } : null)
    const [tool3, setTool3] = useState(tool3Restore ? { label: tool3Restore, value: tool3Restore } : null)

    const { register, handleSubmit, setValue, formState: { errors } } = useForm()

    useEffect(() => {
        if (state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.mainIdea])
            setValue('mainIdea', state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.mainIdea])
    })

    const onSubmit = data => {
        data['tool1'] = removeEmojis(tool1.value).toLowerCase()
        data['tool2'] = removeEmojis(tool2.value).toLowerCase()
        data['tool3'] = removeEmojis(tool3.value).toLowerCase()

        actions.updateToolboxPageData(data)
        next()
    }

    return (
        <Container className="py-5" style={{ fontFamily: 'Markup', minWidth: 997 }}>
            <div className="text-center mb-5">
                <OverlayTrigger placement="top" overlay={<Tooltip className="markup-font" id="toolbox-title-desc">Writers use literary devices as tools to better communicate their messages to readers.</Tooltip>}>
                    <h3 className="m-0 d-inline-block">🧰Literary Devices</h3>
                </OverlayTrigger>
            </div>
            <HandleImage src={handle} />
            <ToolboxContainer>
                <ToolboxImage src={toolbox} />
                <ToolboxColumnsWrapper>
                    <ToolboxColumn>
                        <ToolboxRowWrapper height="50%">
                            <h5>Go-To Tools</h5>
                            <ToolboxRow>
                                {gotoItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                        <ToolboxRowWrapper height="50%">
                            <h5>Fun Tools</h5>
                            <ToolboxRow>
                                {funToolsItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                    </ToolboxColumn>
                    <ToolboxColumn>
                        <ToolboxRowWrapper height="100%">
                            <h5>Figurative Language</h5>
                            <ToolboxRow>
                                {figurativeLangItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                    </ToolboxColumn>
                    <ToolboxColumn>
                        <ToolboxRowWrapper height="calc(100% / 3)">
                            <h5>Reference Tools</h5>
                            <ToolboxRow>
                                {refToolsItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                        <ToolboxRowWrapper height="calc(100% / 3)">
                            <h5>Plot Devices</h5>
                            <ToolboxRow>
                                {plotDevicesItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                        <ToolboxRowWrapper height="calc(100% / 3)">
                            <h5>Misc. Tools</h5>
                            <ToolboxRow>
                                {miscToolsItems.map(([item, def], index) => <ToolboxItem key={index} onClick={() => {
                                    setShowDefModal(true)
                                    setCurrItemTitle(item)
                                    setCurrDefText(def)
                                }}>{item}</ToolboxItem>)}
                            </ToolboxRow>
                        </ToolboxRowWrapper>
                    </ToolboxColumn>
                </ToolboxColumnsWrapper>
            </ToolboxContainer>
            <div style={{ position: 'relative' }} >
                <img src={toolboxBottom} style={{ width: '100%', height: 'auto', marginTop: -6 }} alt="" />
                <div style={{ position: 'absolute', display: 'flex', top: 0, left: 0, height: '100%', width: '100%', flexDirection: 'row' }}>
                    <div style={{ width: 'calc(100% / 3)', height: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
                        <Select options={allOptions} formatGroupLabel={formatGroupLabel} className="toolbox-select" classNamePrefix="toolbox" value={tool1} onChange={setTool1} />
                    </div>
                    <div style={{ width: 'calc(100% / 3)', height: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
                        <Select options={allOptions} formatGroupLabel={formatGroupLabel} className="toolbox-select" classNamePrefix="toolbox" value={tool2} onChange={setTool2} />
                    </div>
                    <div style={{ width: 'calc(100% / 3)', height: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
                        <Select options={allOptions} formatGroupLabel={formatGroupLabel} className="toolbox-select" classNamePrefix="toolbox" value={tool3} onChange={setTool3} />
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <img src={toolboxBottomHandle} style={{ width: '100%', height: 'auto' }} alt="" />
            </div>
            <div>
                <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <TextareaAutosize minRows={3} className={`form-control border ${errors.mainIdea ? 'is-invalid' : ''}`} placeholder="Main Idea"
                            aria-describedby="mainIdeaTextHelp" {...register('mainIdea', { required: true })} />
                        <div id="mainIdeaTextHelp" className="form-text">What is the main message the author is trying to communicate to readers?</div>
                        <div className="invalid-feedback">Hey! You need to fill this in!</div>
                    </div>
                    <Button type="submit" onClick={(e) => {
                        if (!tool1 || !tool2 || !tool3) {
                            e.preventDefault()
                            toast.error(`You have not selected three tools!`, {
                                position: "bottom-right",
                                autoClose: false,
                                hideProgressBar: true,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        } else if (tool1.value === tool2.value || tool2.value === tool3.value || tool1.value === tool3.value) {
                            e.preventDefault()
                            toast.error(`You need choose different three tools!`, {
                                position: "bottom-right",
                                autoClose: false,
                                hideProgressBar: true,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }}>Continue</Button>
                </form>
            </div>

            <Modal show={showDefModal} onHide={() => { setShowDefModal(false) }} size="lg" centered style={{ fontFamily: 'Markup' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {currItemTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currDefText) }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowDefModal(false) }}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Container>
    )
}

export default Toolbox