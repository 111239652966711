import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Button, NavDropdown } from 'react-bootstrap'
import { ActionWrapper } from './ThesisPage'
import { useStateMachine } from 'little-state-machine'
import { updateThesisPreviewPageData } from '../actions'
import { thesisTemplateOrder, transitionWords, conjunctionWords, ThesisPreviewPageKeys, StoreKeys } from '../constants'

const ThesisPreviewContainer = styled.div`
    & div {
        line-height: 2.25;
    }
`

const ThesisSpanContainer = styled.div`
    font-size: 1.25em;
`

const ThesisSegment = styled.span`
    background-color: ${props => props.bgColor};
`

const StyledNavDropdown = styled(NavDropdown)`
    & > a {
        display: inline !important;
    }
`

const ThesisPreviewPage = ({ previous, next }) => {
    const { state, actions } = useStateMachine({ updateThesisPreviewPageData })
    // console.log(state)
    const currTransitionWord = state[StoreKeys.ThesisPreviewPageKey][ThesisPreviewPageKeys.Transition]
    const currConjunctionWord = state[StoreKeys.ThesisPreviewPageKey][ThesisPreviewPageKeys.Conjunction]

    return (
        <ThesisPreviewContainer>
            <ThesisSpanContainer className="mb-3">
                {thesisTemplateOrder.map(([label, bgColor], index) => {
                    if (label === 'opposing') {
                        return (<Fragment key={index}>
                            <StyledNavDropdown title={currTransitionWord} className="d-inline">
                                {transitionWords.map((word) => <NavDropdown.Item
                                    key={word}
                                    eventKey={word}
                                    active={word === currTransitionWord}
                                    onSelect={(eventKey) => actions.updateThesisPreviewPageData({ [ThesisPreviewPageKeys.Transition]: eventKey })}
                                >{word}</NavDropdown.Item>)}
                            </StyledNavDropdown>
                            {' '}
                            <ThesisSegment bgColor={bgColor} className="rounded p-1">{state[StoreKeys.ThesisPageKey][label]}</ThesisSegment>
                            {', '}
                        </Fragment>)
                    } else if (label === 'mainIdea') {
                        return (<Fragment key={index}>
                            <ThesisSegment bgColor={bgColor} className="rounded p-1">{state[StoreKeys.ThesisPageKey][label]}</ThesisSegment>
                            {' '}
                            <StyledNavDropdown title={currConjunctionWord} className="d-inline">
                                {conjunctionWords.map((word) => <NavDropdown.Item
                                    key={word}
                                    eventKey={word}
                                    active={word === currConjunctionWord}
                                    onSelect={(eventKey) => actions.updateThesisPreviewPageData({ [ThesisPreviewPageKeys.Conjunction]: eventKey })}
                                >{word}</NavDropdown.Item>)}
                            </StyledNavDropdown>
                        </Fragment>)
                    }
                    return (<Fragment key={index}>{label === 'reason3' ? 'and ' : ''}<ThesisSegment bgColor={bgColor} className="rounded p-1">{state[StoreKeys.ThesisPageKey][label]}</ThesisSegment>{label !== 'reason3' ? ', ' : '.'}</Fragment>)
                })}
            </ThesisSpanContainer>
            <ActionWrapper className="mb-2">
                <Button variant="primary" onClick={previous}>Let me edit my thesis!</Button>
                <Button variant="primary" onClick={next}>Generate an outline for me!</Button>
            </ActionWrapper>
        </ThesisPreviewContainer>
    )
}

export default ThesisPreviewPage