import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import logo from '../assets/icanwritelogo.png'
import selfie from '../assets/selfie.jpg'

const Logo = styled.img`
    width: 100%;
    height: auto;
`

const Selfie = styled.img`
    max-width: 75%;
    height: auto;
    display: block;
    margin: 0 auto
`

const MissionSection = styled.div`
    margin: 3rem 0;
    text-align: center;

    // & h3 {
    //     font-size: 3rem;
    // }

    & p {
        font-size: 1.2rem;
    }
`

const BioSection = styled.div`
    margin: 3rem 0;
    text-align: center;

    // & h3 {
    //     font-size: 3rem;
    // }

    & p {
        font-size: 1.2rem;
    }
`

const About = () => {
    return (
        <div style={{fontFamily: 'Markup'}}>
            <Navbar expand="lg" sticky="top" variant="light" bg="light">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link href="/">Templates</Nav.Link>
                        <Nav.Link href="/about" active>About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container>
                <div>
                    <Logo src={logo} />
                </div>
                <MissionSection>
                    <h3>Mission</h3>
                    <p>
                        I Can Write! is an organization dedicated to empowering students as writers.
                    </p>
                    <p>
                        We seek to make the writing process more manageable and fun for students. Our interactive templates are designed to help students organize and develop their ideas into effective essays. Instead of using technology to procrastinate from writing, students can use our technology to guide them throughout every step of the writing process. We are committed to making our platform accessible to help all students meet their individual writing needs.
                    </p>
                </MissionSection>

                <div>
                    <Selfie src={selfie} />
                </div>
                <BioSection>
                    <h3>About the Founder</h3>
                    <p>Hi, my students call me Ms. Zhou, but you can call me Jess! I am an educator living in Los Angeles, CA. This past year, I was teaching kindergarten by day and private tutoring middle/high school students by night to pay my bills. I soon realized that the same techniques (modeling, sentence frames, and graphic organizers) used to accommodate young students and English language learners could be used to help older students learn to write. To bring my vision to life, I created I Can Write!</p>

                    <p>If you have any questions or feedback, please don't hesitate to contact me at <a href='mailto:jz8@alumni.princeton.edu'>jz8@alumni.princeton.edu</a></p>

                </BioSection>
            </Container>
        </div>
    )
}

export default About