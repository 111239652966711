import { useStateMachine } from 'little-state-machine'
import React, { Fragment } from 'react'
import { Button, NavDropdown } from 'react-bootstrap'
import { ArrowLeftRight } from 'react-bootstrap-icons'
import styled from 'styled-components'
import { updateToolboxThesisPageData } from '../actions'
import { toolboxLinkWords, ToolboxPageKeys, ToolboxStoreKeys, ToolboxThesisPageKeys, toolboxThesisTemplateAltOrder, toolboxThesisTemplateOrder, toolboxTransitionWords } from '../constants'
import { ActionWrapper } from './ThesisPage'

const ThesisPreviewContainer = styled.div`
    & div {
        line-height: 2.25;
    }
`

const ThesisSpanContainer = styled.div`
    font-size: 1.25em;
`

const ThesisSegment = styled.span`
    background-color: ${props => props.bgColor};
`

const StyledNavDropdown = styled(NavDropdown)`
    & > a {
        display: inline !important;
    }

    & .empty-select {
        min-height: 44px;
    }
`

const ToolboxThesisPage = ({ previous, next }) => {
    const { state, actions } = useStateMachine({ updateToolboxThesisPageData })
    // console.log(state)
    const currTransitionWord = state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Transition]
    const currLinkWord = state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Link]
    const isSwapped = state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Swap]

    return (
        <ThesisPreviewContainer>
            <Button onClick={() => { actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Swap]: !isSwapped }) }}><ArrowLeftRight /></Button>
            {!isSwapped ? (<ThesisSpanContainer className="mb-3">
                {toolboxThesisTemplateOrder.map(([label, bgColor], index) => {
                    if (label === 'mainIdea') {
                        return (<Fragment key={index}>
                            <StyledNavDropdown title={currTransitionWord} className="d-inline">
                                {toolboxTransitionWords.map((word) =>
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currTransitionWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Transition]: eventKey })}
                                    >{word}</NavDropdown.Item>
                                )}
                            </StyledNavDropdown>
                            <StyledNavDropdown title={currLinkWord} className="d-inline">
                                {toolboxLinkWords.map((word) => word === '' ?
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currLinkWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Link]: eventKey })}
                                        className="empty-select"
                                    >{word}</NavDropdown.Item>
                                    :
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currLinkWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Link]: eventKey })}
                                    >{word}</NavDropdown.Item>
                                )}
                            </StyledNavDropdown>
                            <ThesisSegment bgColor={bgColor} className="rounded p-1">{state[ToolboxStoreKeys.ToolboxPage][label]}</ThesisSegment>
                            {', the author uses '}
                        </Fragment>)
                    }
                    return (<Fragment key={index}>{label === 'tool3' ? 'and ' : ''}<ThesisSegment bgColor={bgColor} className="rounded p-1">{state[ToolboxStoreKeys.ToolboxPage][label]}</ThesisSegment>{label !== 'tool3' ? ', ' : '.'}</Fragment>)
                })}
            </ThesisSpanContainer>
            ) : (<ThesisSpanContainer className="mb-3">
                {toolboxThesisTemplateAltOrder.map(([label, bgColor], index) => {
                    if (label === 'tool1') {
                        return (
                            <Fragment>
                                {'The author uses '}
                                <ThesisSegment bgColor={bgColor} className="rounded p-1">{state[ToolboxStoreKeys.ToolboxPage][label]}</ThesisSegment>
                                {', '}
                            </Fragment>
                        )
                    } else if (label === 'mainIdea') {
                        return (<Fragment key={index}>
                            <StyledNavDropdown title={currTransitionWord.toLowerCase()} className="d-inline">
                                {toolboxTransitionWords.map((word) =>
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currTransitionWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Transition]: eventKey })}
                                    >{word.toLowerCase()}</NavDropdown.Item>
                                )}
                            </StyledNavDropdown>
                            <StyledNavDropdown title={currLinkWord} className="d-inline">
                                {toolboxLinkWords.map((word) => word === '' ?
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currLinkWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Link]: eventKey })}
                                        className="empty-select"
                                    >{word}</NavDropdown.Item>
                                    :
                                    <NavDropdown.Item
                                        key={word}
                                        eventKey={word}
                                        active={word === currLinkWord}
                                        onSelect={(eventKey) => actions.updateToolboxThesisPageData({ [ToolboxThesisPageKeys.Link]: eventKey })}
                                    >{word}</NavDropdown.Item>
                                )}
                            </StyledNavDropdown>
                            <ThesisSegment bgColor={bgColor} className="rounded p-1">{state[ToolboxStoreKeys.ToolboxPage][label]}</ThesisSegment>
                            {'.'}
                        </Fragment>)
                    }
                    return (<Fragment key={index}>{label === 'tool3' ? ' and ' : ''}<ThesisSegment bgColor={bgColor} className="rounded p-1">{state[ToolboxStoreKeys.ToolboxPage][label]}</ThesisSegment></Fragment>)
                })}
            </ThesisSpanContainer>)}
            <ActionWrapper className="mb-2">
                <Button variant="primary" onClick={previous}>Let me edit my choices!</Button>
                <Button variant="primary" onClick={() => {
                    if (!isSwapped)
                        actions.updateToolboxThesisPageData({
                            [ToolboxThesisPageKeys.Thesis]:
                                state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Transition]
                                + ' ' + state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Link] + (state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Link] && ' ')
                                + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.mainIdea]
                                + ', the author uses ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool1]
                                + ', ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool2] + ', and ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool3] + '.'
                        })
                    else
                        actions.updateToolboxThesisPageData({
                            [ToolboxThesisPageKeys.Thesis]:
                                'The author uses ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool1]
                                + ', ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool2] + ', and ' + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.Tool3]
                                + ' ' + state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Transition].toLowerCase()
                                + ' ' + state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Link] + (state[ToolboxStoreKeys.ToolboxThesisPage][ToolboxThesisPageKeys.Link] && ' ')
                                + state[ToolboxStoreKeys.ToolboxPage][ToolboxPageKeys.mainIdea] + '.'
                        })
                    next()
                }}>Generate an outline for me!</Button>
            </ActionWrapper>
        </ThesisPreviewContainer>
    )
}

export default ToolboxThesisPage