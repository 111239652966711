import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Button } from 'react-bootstrap'

const FullScreenWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ff6961;
    text-align: center;
    padding: 3rem;
`

export const withPhoneWarning = WrappedComponent => props => {
    const [ignoreWarning, setIgnoreWarning] = useState(false)
    const isPhoneScreen = useMediaQuery({ query: '(max-width: 767.98px)' })

    if (!ignoreWarning && isPhoneScreen)
        return (
            <FullScreenWrapper>
                <h4>For optimal display, please use your computer. You really shouldn't be writing your essay on your phone, but you do you!</h4>
                <Button className="mt-3" variant="light" onClick={() => {setIgnoreWarning(true)}}>Continue</Button>
            </FullScreenWrapper>)
    else
        return <WrappedComponent {...props} />
}