// Little State Machine
export const StoreKeys = {
    ThesisPageKey: 'thesisPage',
    ThesisPreviewPageKey: 'thesisPreviewPage',
    OutlinePageKey: 'outlinePage',
    OptionsKey: 'options',
}

export const ThesisPageKeys = {
    Topic: 'topic',
    MainIdea: 'mainIdea',
    Reason1: 'reason1',
    Reason2: 'reason2',
    Reason3: 'reason3',
    Opposing: 'opposing',
}

export const ThesisPreviewPageKeys = {
    Transition: 'transition',
    Conjunction: 'conjunction',
}

export const OutlinePageKeys = {
    Hook: 'hook',
    Background: 'background',
    Thesis: 'thesis',
    Topic1: 'topic1',
    Evidence1: 'evidence1',
    Transition1: 'transition1',
    Evidence2: 'evidence2',
    Conclusion1: 'conclusion1',
    Topic2: 'topic2',
    Evidence3: 'evidence3',
    Transition2: 'transition2',
    Evidence4: 'evidence4',
    Conclusion2: 'conclusion2',
    Topic3: 'topic3',
    Evidence5: 'evidence5',
    Transition3: 'transition3',
    Evidence6: 'evidence6',
    Conclusion3: 'conclusion3',
    RestateThesis: 'restateThesis',
    Summary: 'summary',
    Generalize: 'generalize',
}

export const OptionsKeys = {
    Hook: 'hook',
    Evidence1: 'evidence1',
    Evidence2: 'evidence2',
    Evidence3: 'evidence3',
    Evidence4: 'evidence4',
    Evidence5: 'evidence5',
    Evidence6: 'evidence6',
}

export const QuoteKeySuffixes = {
    Introduce: ['_intro', 'Introduce', 'Introduce the quote.'],
    Insert: ['_insert', 'Insert', 'Insert or paraphrase the quote.'],
    Explain: ['_explain', 'Explain', 'Explain the quote.'],
    Connect: ['_connect', 'Connect', 'Connect the quote back to your main idea.'],
}

// MasterForm
export const themes = [
    'linear-gradient(180deg, #69b7eb, #b3dbd3, #f4d6db)',
    'linear-gradient(180deg, #cfecd0, #ffc5ca)',
    'linear-gradient(180deg, #f598a8, #f6edb2)',
    'linear-gradient(180deg, #ee5c87, #f1a4b5, #d587b3)',
    'linear-gradient(180deg, #b9deed, #efefef)',
    'linear-gradient(180deg, #aea4e3, #d3ffe8)',
    'linear-gradient(180deg, #faf0cd, #fab397)',
    'linear-gradient(180deg, #cfecd0, #a0cea7, #9ec0db)',
    'black',
    'white',
]

export const fonts = [
    'Courier New',
    'Times New Roman',
    'Bellota',
    'Architects Daughter',
    'Comic Sans MS'
]


export const fontColors = [
    '#000000',
    '#fff',
    '#ff3f3f',
    '#0057e7',
    '#0abd00',
    '#6b3e26',
    '#ffc5d9',
    '#c2f2d0',
    '#fdf5c9',
    '#ffcb85',
    '#ff00ff',
    '#ffff00',
    '#00ffff',
    '#00ff07',
    '#772cb6',
    '#ff71ce',
    '#01cdfe',
    '#05ffa1',
    '#b967ff',
    '#fffb96',
]

// ThesisPreviewPage
export const thesisTemplateOrder = [
    ['opposing', '#E4C1F9'],
    ['mainIdea', '#FF99C8'],
    ['reason1', '#FCF6BD'],
    ['reason2', '#D0F4DE'],
    ['reason3', '#A9DEF9'],
]

export const transitionWords = [
    'While',
    'While some say',
    'Although',
    'Even though',
    'Despite the popular conception that',
]

export const conjunctionWords = [
    'because',
    'by',
    'since',
    'as a result of',
    'considering how',
    'in that',
    ', such as',
    '; for example:',
    ', including'
]


// OutlinePage
export const groups = {
    paragraph1: ['hook', 'background', 'thesis'],
    paragraph2: ['topic1', 'evidence1', 'transition1', 'evidence2', 'conclusion1'],
    paragraph3: ['topic2', 'evidence3', 'transition2', 'evidence4', 'conclusion2'],
    paragraph4: ['topic3', 'evidence5', 'transition3', 'evidence6', 'conclusion3'],
    paragraph5: ['restateThesis', 'summary', 'generalize'],
}

export const hookOptions = [
    ['Descriptive Imagery🖼️',
        'Illustrate a scene that immerses readers into the world of your essay topic.',
        'Example: Hamburger. Cheeseburger. Double-double. Fries. Soda. Shake. This is the entire menu of In-N-Out Burger, and this menu has not changed much since its founding in 1984.'],
    ['Shocking Statistic❗',
        'Provide an interesting research-based fact that leaves the readers wanting to learn more.',
        'Example: Walmart sells over 75 million products, but CEO Doug McMillon revealed that the top-selling product is: bananas!'],
    ['Universal Message🌍',
        'Begin with a general statement that is relatable to everyone.',
        'Example: We all want to live in a fair world.'],
    ['Question❓',
        'Ask readers a question that demands answers.',
        'Example: Are babies born racist, or do they learn racism?'],
    ['Quotation💬',
        'Start with a thought-provoking quote.',
        'Example: Cultural icon Jaden Smith once tweeted, "How Can Mirrors Be Real If Our Eyes Aren\'t Real."'],
]

export const evidenceOptions = [
    ['Personal Anecdote', '', ''],
    ['Book Quote', '', ''],
    ['Research Finding', '', ''],
]

// Toolbox Page
export const ToolboxStoreKeys = {
    ToolboxPage: 'toolboxPage',
    ToolboxThesisPage: 'toolboxThesisPage',
    ToolboxOutlinePage: 'toolboxOutlinePage',
    ToolboxOptions: 'toolboxOptions',
}

export const ToolboxPageKeys = {
    Tool1: 'tool1',
    Tool2: 'tool2',
    Tool3: 'tool3',
    mainIdea: 'mainIdea',
}

export const ToolboxThesisPageKeys = {
    Transition: 'transition',
    Swap: 'swap',
    Link: 'link',
    Thesis: 'thesis',
}

export const toolboxTransitionWords = [
    'To communicate',
    'To convey',
    'To demonstrate',
    'To depict',
    'To explain',
    'To express',
    'To illustrate',
    'To reveal',
]

export const toolboxLinkWords = [
    '',
    'how',
    'that'
]

export const toolboxThesisTemplateOrder = [
    ['mainIdea', '#FF99C8'],
    ['tool1', '#FCF6BD'],
    ['tool2', '#D0F4DE'],
    ['tool3', '#A9DEF9'],
]

export const toolboxThesisTemplateAltOrder = [
    ['tool1', '#FCF6BD'],
    ['tool2', '#D0F4DE'],
    ['tool3', '#A9DEF9'],
    ['mainIdea', '#FF99C8'],
]

export const textToEmoji = {
    'linear structure': '➡️Linear Structure',
    'non-linear structure': '📈Non-linear Structure',
    'repetitive structure': '🖇️Repetitive Structure',
    'free verse form': '🦋Free Verse Form',
    'rhyming verse form': '🎶Rhyming Verse Form',
    'formal diction': '👔Formal Diction',
    'informal diction': '👖Informal Diction',
    'slang diction': '🕶️Slang Diction',
    'repetitive diction': '‼️Repetitive Diction',
    'sensory imagery': '👀Sensory Imagery',
    'natural imagery': '🌱Natural Imagery',
    'pastoral imagery': '🌄Pastoral Imagery',
    'urban imagery': '🏙️Urban Imagery',
    'materialistic imagery': '🛍️Materialistic Imagery',
    'fragmented imagery': '🧩Fragmented Imagery',
    'romantic imagery': '💞Romantic Imagery',
    'nostalgic imagery': '🎠Nostalgic Imagery',
    'emotional imagery': '😭Emotional Imagery',
    'food imagery': '🍔Food Imagery',
    'humor': '😂Humor',
    'satire': '🤣Satire',
    'verbal irony': '🙊Verbal Irony',
    'dramatic irony': '🙉Dramatic Irony',
    'situational irony': '🙈Situational Irony',
    'anachronism': '🎎🎮Anachronism',
    'simile': '🙂☀️Simile',
    'analogy': '☕Analogy',
    'metaphor': '🙆‍♀️🦄Metaphor',
    'extended metaphor': '💬Extended Metaphor',
    'personification': '🌞Personification',
    'symbol': '🗽Symbol',
    'motif': '🗽🗽Motif',
    'oxymoron': '🔴🚫Oxymoron',
    'hyperbole': '😯Hyperbole',
    'biblical allegory': '🙏Biblical Allegory',
    'classical allegory': '🏛️Classical Allegory',
    'modern allegory': '📱Modern Allegory',
    'allusion': '😉Allusion',
    'cliffhanger': '🧗‍♀️Cliffhanger',
    'concrete foreshadowing': '⚠️Concrete Foreshadowing',
    'prominent foreshadowing': '🧙‍♀️Prominent Foreshadowing',
    'evocative foreshadowing': '💥Evocative Foreshadowing',
    'abstract foreshadowing': '🌪️Abstract Foreshadowing',
    'red herring foreshadowing': '🚩Red Herring Foreshadowing',
    'paradox': '🤷‍♀️Paradox',
    'verisimilitude': '🏡Verisimilitude',
    'vignette': '🔍Vignette',
}