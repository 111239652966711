import {
    conjunctionWords,
    OptionsKeys,
    OutlinePageKeys,
    StoreKeys,
    ThesisPageKeys,
    ThesisPreviewPageKeys,
    toolboxLinkWords,
    ToolboxPageKeys,
    ToolboxStoreKeys,
    ToolboxThesisPageKeys,
    toolboxTransitionWords,
    transitionWords
} from './constants'

export const initialState = {
    [StoreKeys.ThesisPageKey]: {
        [ThesisPageKeys.Topic]: '',
        [ThesisPageKeys.MainIdea]: '',
        [ThesisPageKeys.Reason1]: '',
        [ThesisPageKeys.Reason2]: '',
        [ThesisPageKeys.Reason3]: '',
        [ThesisPageKeys.Opposing]: '',
    },
    [StoreKeys.ThesisPreviewPageKey]: {
        [ThesisPreviewPageKeys.Transition]: transitionWords[0],
        [ThesisPreviewPageKeys.Conjunction]: conjunctionWords[0],
    },
    [StoreKeys.OutlinePageKey]: {
        [OutlinePageKeys.Hook]: '',
        [OutlinePageKeys.Background]: '',
        [OutlinePageKeys.Thesis]: '',
        [OutlinePageKeys.Topic1]: '',
        [OutlinePageKeys.Evidence1]: '',
        [OutlinePageKeys.Evidence2]: '',
        [OutlinePageKeys.Conclusion1]: '',
        [OutlinePageKeys.Topic2]: '',
        [OutlinePageKeys.Evidence3]: '',
        [OutlinePageKeys.Evidence4]: '',
        [OutlinePageKeys.Conclusion2]: '',
        [OutlinePageKeys.Topic3]: '',
        [OutlinePageKeys.Evidence5]: '',
        [OutlinePageKeys.Evidence6]: '',
        [OutlinePageKeys.Conclusion3]: '',
        [OutlinePageKeys.RestateThesis]: '',
        [OutlinePageKeys.Summary]: '',
        [OutlinePageKeys.Generalize]: '',
    },
    [StoreKeys.OptionsKey]: {
        [OptionsKeys.Hook]: -1,
        [OptionsKeys.Evidence1]: -1,
        [OptionsKeys.Evidence2]: -1,
        [OptionsKeys.Evidence3]: -1,
        [OptionsKeys.Evidence4]: -1,
        [OptionsKeys.Evidence5]: -1,
        [OptionsKeys.Evidence6]: -1,
    }
}

export const toolboxInitialState = {
    [ToolboxStoreKeys.ToolboxPage]: {
        [ToolboxPageKeys.Tool1]: null,
        [ToolboxPageKeys.Tool2]: null,
        [ToolboxPageKeys.Tool3]: null,
        [ToolboxPageKeys.MainIdea]: '',
    },
    [ToolboxStoreKeys.ToolboxThesisPage]: {
        [ToolboxThesisPageKeys.Transition]: toolboxTransitionWords[0],
        [ToolboxThesisPageKeys.Link]: toolboxLinkWords[0],
        [ToolboxThesisPageKeys.Thesis]: '',
        [ToolboxThesisPageKeys.Swap]: false,
    },
    [ToolboxStoreKeys.ToolboxOutlinePage]: {
        [OutlinePageKeys.Hook]: '',
        [OutlinePageKeys.Background]: '',
        [OutlinePageKeys.Thesis]: '',
        [OutlinePageKeys.Topic1]: '',
        [OutlinePageKeys.Evidence1]: '',
        [OutlinePageKeys.Evidence2]: '',
        [OutlinePageKeys.Conclusion1]: '',
        [OutlinePageKeys.Topic2]: '',
        [OutlinePageKeys.Evidence3]: '',
        [OutlinePageKeys.Evidence4]: '',
        [OutlinePageKeys.Conclusion2]: '',
        [OutlinePageKeys.Topic3]: '',
        [OutlinePageKeys.Evidence5]: '',
        [OutlinePageKeys.Evidence6]: '',
        [OutlinePageKeys.Conclusion3]: '',
        [OutlinePageKeys.RestateThesis]: '',
        [OutlinePageKeys.Summary]: '',
        [OutlinePageKeys.Generalize]: '',
    },
    [ToolboxStoreKeys.ToolboxOptions]: {
        [OptionsKeys.Hook]: 1,
        [OptionsKeys.Evidence1]: 1,
        [OptionsKeys.Evidence2]: 1,
        [OptionsKeys.Evidence3]: 1,
        [OptionsKeys.Evidence4]: 1,
        [OptionsKeys.Evidence5]: 1,
        [OptionsKeys.Evidence6]: 1,
    }
}

// 5 Paragraph Template actions
export const updateThesisPageData = (state, payload) => ({ ...state, [StoreKeys.ThesisPageKey]: { ...state[StoreKeys.ThesisPageKey], ...payload } })
export const updateThesisPreviewPageData = (state, payload) => ({ ...state, [StoreKeys.ThesisPreviewPageKey]: { ...state[StoreKeys.ThesisPreviewPageKey], ...payload } })
export const updateOutlinePageData = (state, payload) => ({ ...state, [StoreKeys.OutlinePageKey]: { ...state[StoreKeys.OutlinePageKey], ...payload } })
export const updateOptions = (state, payload) => ({ ...state, [StoreKeys.OptionsKey]: { ...state[StoreKeys.OptionsKey], ...payload } })

// Toolbox actions
export const updateToolboxPageData = (state, payload) => ({ ...state, [ToolboxStoreKeys.ToolboxPage]: { ...state[ToolboxStoreKeys.ToolboxPage], ...payload } })
export const updateToolboxThesisPageData = (state, payload) => ({ ...state, [ToolboxStoreKeys.ToolboxThesisPage]: { ...state[ToolboxStoreKeys.ToolboxThesisPage], ...payload } })
export const updateToolboxOutlinePageData = (state, payload) => ({ ...state, [ToolboxStoreKeys.ToolboxOutlinePage]: { ...state[ToolboxStoreKeys.ToolboxOutlinePage], ...payload } })
export const updateToolboxOptions = (state, payload) => ({ ...state, [ToolboxStoreKeys.ToolboxOptions]: { ...state[ToolboxStoreKeys.ToolboxOptions], ...payload } })