import { useStateMachine } from 'little-state-machine';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { initialState, updateOutlinePageData, updateThesisPageData, updateThesisPreviewPageData, updateOptions } from '../actions';
import { StoreKeys, ThesisPageKeys } from '../constants';

export const ActionWrapper = styled.div`
    display: flex;
    column-gap: 10px;
`

const LimitWarningActionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const Emphasis = styled.span`
    font-style: italic;
`

const ThesisPage = ({ next }) => {
    const labels = {
        [ThesisPageKeys.Topic]: { name: 'topic', ref: useRef(null) },
        [ThesisPageKeys.MainIdea]: { name: 'main idea', ref: useRef(null) },
        [ThesisPageKeys.Reason1]: { name: 'first reason', ref: useRef(null) },
        [ThesisPageKeys.Reason2]: { name: 'second reason', ref: useRef(null) },
        [ThesisPageKeys.Reason3]: { name: 'third reason', ref: useRef(null) },
        [ThesisPageKeys.Opposing]: { name: 'opposing viewpoint', ref: useRef(null) }
    }
    const example1 = [
        'Who\'s the most iconic singer of all time?',
        'Beyonce is the most iconic singer of all time',
        'her voice shatters Grammy award records',
        'her music videos stand as cinematic masterpieces',
        'her songs empower audiences all over the world',
        'haters say she is overrated'
    ]

    const [disableSubmit, setDisableSubmit] = useState(false)
    const [showClearWarning, setShowClearWarning] = useState(false)
    const [wordLimitWarnings, setWordLimitWarnings] = useState([false, false, false, false, false, false])
    const { actions, state } = useStateMachine({ updateThesisPageData, updateThesisPreviewPageData, updateOutlinePageData, updateOptions })

    const { register, handleSubmit, setValue, watch, formState: { errors }, setFocus } = useForm()

    const saveAndContinue = data => {
        // console.log(data)
        actions.updateThesisPageData(data)
        next()
    }

    const LimitWarning = ({ closeToast, message, data, scrollTarget, target }) => (
        <div>
            <div className="mb-2">{message}</div>
            <LimitWarningActionWrapper>
                <Button variant="primary" onClick={() => {
                    closeToast()
                    saveAndContinue(data)
                }}>Continue</Button>
                <Button variant="secondary" onClick={() => {
                    closeToast()
                    scrollTarget.current.scrollIntoView({ behavior: 'smooth' })
                    setTimeout(() => {
                        setFocus(target)
                    }, 300)
                }}>Edit</Button>
            </LimitWarningActionWrapper>
        </div>
    )
    const onSubmit = data => {
        let noWarnings = true
        let newWordLimitState = []

        for (const [key, val] of Object.entries(data)) {
            const words = val.split(' ')
            if (words.length > 10) {
                toast.warn(<LimitWarning
                    message={`Hey, it looks like your ${labels[key].name} box is really wordy 🙊 Can you think of a simpler way of saying the same thing? If not, click continue.`}
                    data={data}
                    scrollTarget={labels[key].ref}
                    target={key}
                />, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onOpen: () => setDisableSubmit(true),
                    onClose: () => setDisableSubmit(false),
                })
                noWarnings = false
                newWordLimitState.push(true)
            } else {
                newWordLimitState.push(false)
            }
        }
        setWordLimitWarnings(newWordLimitState)
        if (!noWarnings)
            return
        saveAndContinue(data)
    }

    useEffect(() => {
        for (const [key, val] of Object.entries(state[StoreKeys.ThesisPageKey])) {
            if (val)
                setValue(key, val)
        }
    }, [state, setValue])

    const lastFormData = useRef(null)
    const currFormData = watch()

    useEffect(() => {
        if (!isEqual(lastFormData.current, currFormData)) {
            // console.log('saving after 2000ms?')
            actions.updateThesisPageData(currFormData)
            lastFormData.current = currFormData
        }
    }, [actions, currFormData])

    return (
        <div>
            <ActionWrapper className="mb-2">
                <Button variant="primary" onClick={() => {
                    for (const [i, label] of Object.keys(labels).entries()) {
                        setValue(label, example1[i])
                    }
                }}>Show me an example!🔮</Button>
                <Button variant="danger" onClick={() => { setShowClearWarning(true) }}>Erase❌</Button>
            </ActionWrapper>

            <form id="thesisGenerator" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <h5 ref={labels.topic.ref}>1. What's your motivating question?</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.topic || wordLimitWarnings[0] ? 'is-invalid' : ''}`} placeholder="Topic"
                        aria-describedby="topicTextHelp" {...register('topic', { required: true })} />
                    <div id="topicTextHelp" className="form-text">This is the driving force behind your essay</div>
                    {!wordLimitWarnings[0] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>
                <h5 ref={labels.mainIdea.ref}>2. What’s your main idea?</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.mainIdea || wordLimitWarnings[1] ? 'is-invalid' : ''}`} placeholder="Main Idea"
                        aria-describedby="mainIdeaTextHelp" {...register('mainIdea', { required: true })} />
                    <div id="mainIdeaTextHelp" className="form-text">What is the most important thing you want to tell your readers?</div>
                    {!wordLimitWarnings[1] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>
                <h5 ref={labels.reason1.ref}>3. What's a reason that supports your main idea?</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.reason1 || wordLimitWarnings[2] ? 'is-invalid' : ''}`} placeholder="Reason 1"
                        aria-describedby="reasonTextHelp1" {...register('reason1', { required: true })} />
                    <div id="reasonTextHelp1" className="form-text">Make sure you can support Reason #1 with evidence</div>
                    {!wordLimitWarnings[2] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>
                <h5 ref={labels.reason2.ref}>4. What's <Emphasis>another</Emphasis> reason that supports your main idea?</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.reason2 || wordLimitWarnings[3] ? 'is-invalid' : ''}`} placeholder="Reason 2"
                        aria-describedby="reasonTextHelp2" {...register('reason2', { required: true })} />
                    <div id="reasonTextHelp2" className="form-text">Make sure you can support Reason #2 with evidence</div>
                    {!wordLimitWarnings[3] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>
                <h5 ref={labels.reason3.ref}>5. What's one <Emphasis>more</Emphasis> reason that supports your main idea?</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.reason3 || wordLimitWarnings[4] ? 'is-invalid' : ''}`} placeholder="Reason 3"
                        aria-describedby="reasonTextHelp3" {...register('reason3', { required: true })} />
                    <div id="reasonTextHelp3" className="form-text">Make sure you can support Reason #3 with evidence</div>
                    {!wordLimitWarnings[4] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>
                <h5 ref={labels.opposing.ref}>6. Include an opposing viewpoint to your main idea</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.opposing || wordLimitWarnings[5] ? 'is-invalid' : ''}`} placeholder="Opposing view"
                        aria-describedby="opposingTextHelp" {...register('opposing', { required: true })} />
                    <div id="opposingTextHelp" className="form-text">What have haters been saying about your topic?</div>
                    {!wordLimitWarnings[5] && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>

                <Button type="submit" variant="primary" disabled={disableSubmit}>Generate my thesis!</Button>
            </form>
            <Modal show={showClearWarning} onHide={() => setShowClearWarning(false)}>
                <Modal.Header>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you <strong>sure</strong> you want to clear all boxes?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        actions.updateThesisPageData(initialState[StoreKeys.ThesisPageKey])
                        actions.updateThesisPreviewPageData(initialState[StoreKeys.ThesisPreviewPageKey])
                        actions.updateOutlinePageData(initialState[StoreKeys.OutlinePageKey])
                        actions.updateOptions(initialState[StoreKeys.OptionsKey])

                        for (const key of Object.keys(state[StoreKeys.ThesisPageKey])) {
                            setValue(key, '')
                        }

                        setShowClearWarning(false)
                    }}>Clear</Button>
                    <Button variant="secondary" onClick={() => setShowClearWarning(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    )
}

export default ThesisPage