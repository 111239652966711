import axios from 'axios'
import { useStateMachine } from 'little-state-machine'
import { isEmpty, isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, Form, Modal, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { updateToolboxOutlinePageData, updateToolboxOptions } from '../actions'
import { evidenceOptions, fonts, groups, hookOptions, OptionsKeys, OutlinePageKeys, QuoteKeySuffixes, ToolboxStoreKeys } from '../constants'
import Confetti from './Confetti'
import { ActionWrapper } from './ThesisPage'

const previewBgColors = [
    '#FF99C8',
    '#FCF6BD',
    '#D0F4DE',
    '#A9DEF9',
    '#E4C1F9',
]

const StyledNavbar = styled(Navbar)`
    width: 100%;
    padding: 0;
`

const LinkStyleButton = styled.button`
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
`

const HookOptionsContainer = styled.div`
    padding: .375rem .75rem;
    border-radius: .25rem;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-color: rgba(255, 255, 255, 0.3) !important;

    & p {
        margin: 0;
    }
`

const HookOptions = ({ handleChoose }) => {
    const [selectedOption, setSelectedOption] = useState(0)

    return (
        <HookOptionsContainer className="mb-3">
            <p>What kind of hook do you want to write?</p>

            <div>
                {hookOptions.map(([option, helpText, example], index) =>
                (<OverlayTrigger key={index} placement="left" overlay={<Tooltip id={`hook-option-tooltip-${index}`}>{helpText}</Tooltip>}>
                    <Form.Check
                        type="radio"
                        id={`hook-option-${index}`}
                        label={option}
                        name="hook-options"
                        value={option}
                        checked={selectedOption === index}
                        onChange={() => setSelectedOption(index)} />
                </OverlayTrigger>))}
                <Button onClick={() => { handleChoose(selectedOption) }}>Choose</Button>
            </div>
        </HookOptionsContainer>
    )
}

const evidenceKeyNames = {
    [OptionsKeys.Evidence1]: 'Evidence #1',
    [OptionsKeys.Evidence2]: 'Evidence #2',
    [OptionsKeys.Evidence3]: 'Evidence #1',
    [OptionsKeys.Evidence4]: 'Evidence #2',
    [OptionsKeys.Evidence5]: 'Evidence #1',
    [OptionsKeys.Evidence6]: 'Evidence #2',
}

const evidenceKeyReasonNames = {
    [OptionsKeys.Evidence1]: 'reason #1',
    [OptionsKeys.Evidence2]: 'reason #2',
    [OptionsKeys.Evidence3]: 'reason #1',
    [OptionsKeys.Evidence4]: 'reason #2',
    [OptionsKeys.Evidence5]: 'reason #1',
    [OptionsKeys.Evidence6]: 'reason #2',
}


const EvidenceGroup = ({ evidenceKey, actions, errors, register }) => {
    const keys = Object.values(QuoteKeySuffixes).map(([suffix, action, placeholder]) => [`${evidenceKey}${suffix}`, action, placeholder])

    return (
        <>
            <h6>{evidenceKeyNames[evidenceKey]}</h6>
            <HookOptionsContainer className="pt-3 pb-0 mb-3">
                {
                    keys.map(([key, action, placeholder], index) => (
                        <div className="mb-3" key={index}>
                            <TextareaAutosize minRows={3} className={`form-control ${errors[key] ? 'is-invalid' : ''}`} placeholder={action}
                                aria-describedby={`${evidenceKey}-${key}-text-help`} {...register(key, { required: true })} />
                            <div id={`${evidenceKey}-${key}-text-help`} className="form-text">{placeholder}</div>
                            <div className="invalid-feedback">Hey! You need to fill this in!</div>
                        </div>
                    ))
                }
            </HookOptionsContainer>
        </>
    )
}

const EvidenceOptions = ({ evidenceKey, handleChoose }) => {
    const [selectedOption, setSelectedOption] = useState(0)

    return (
        <>
            <h6>{evidenceKeyNames[evidenceKey]}</h6>
            <HookOptionsContainer className="mb-3">
                <p>What kind of evidence do you want to use?</p>

                <div>
                    {evidenceOptions.map(([option, helpText, example], index) =>
                    (<Form.Check
                        key={index}
                        type="radio"
                        id={`${evidenceKey}-option-${index}`}
                        label={option}
                        name={`${evidenceKey}-options`}
                        value={option}
                        checked={selectedOption === index}
                        onChange={() => setSelectedOption(index)} />
                    ))}
                    <Button onClick={() => { handleChoose(selectedOption) }}>Choose</Button>
                </div>
            </HookOptionsContainer>
        </>
    )
}

const EvidenceContainer = ({ evidenceKey, currEvidenceOption, errors, actions, register }) => {
    if (currEvidenceOption) {
        if (currEvidenceOption === evidenceOptions[0][0])
            return (<div className="mb-3">
                <TextareaAutosize minRows={3} className={`form-control ${errors[evidenceKey] ? 'is-invalid' : ''}`} placeholder={`${evidenceKeyNames[evidenceKey]} (${currEvidenceOption})`}
                    aria-describedby={`${evidenceKey}TextHelp`} {...register(evidenceKey, { required: true })} />
                <div id={`${evidenceKey}TextHelp`} className="form-text">Explain a quote/example that supports {evidenceKeyReasonNames[evidenceKey]}</div>
                <div className="invalid-feedback">Hey! You need to fill this in!</div>
            </div>)
        else
            return <EvidenceGroup evidenceKey={evidenceKey} actions={actions} errors={errors} register={register} />
    } else {
        return <EvidenceOptions evidenceKey={evidenceKey} handleChoose={(index) => { actions.updateToolboxOptions({ [evidenceKey]: index }) }} />
    }
}

const ToolboxOutlinePage = ({ previous, showPreview, handlePreviewClose }) => {
    const labelProps = {
        [OutlinePageKeys.Hook]: { name: 'hook' },
        [OutlinePageKeys.Background]: { name: 'background info' },
        [OutlinePageKeys.Thesis]: { name: 'thesis' },
        [OutlinePageKeys.Topic1]: { name: 'first topic sentence' },
        [OutlinePageKeys.Evidence11]: { name: 'first body paragraph evidence' },
        [OutlinePageKeys.Evidence21]: { name: 'first body paragraph evidence' },
        [OutlinePageKeys.Conclusion1]: { name: 'first conclusion sentence' },
        [OutlinePageKeys.Topic2]: { name: 'second topic sentence' },
        [OutlinePageKeys.Evidence12]: { name: 'second body paragraph evidence' },
        [OutlinePageKeys.Evidence22]: { name: 'second body paragraph evidence' },
        [OutlinePageKeys.Conclusion2]: { name: 'second conclusion sentence' },
        [OutlinePageKeys.Topic3]: { name: 'third topic sentence' },
        [OutlinePageKeys.Evidence13]: { name: 'third body paragraph evidence' },
        [OutlinePageKeys.Evidence23]: { name: 'third body paragraph evidence' },
        [OutlinePageKeys.Conclusion3]: { name: 'third conclusion sentence' },
        [OutlinePageKeys.RestateThesis]: { name: 'thesis restatement' },
        [OutlinePageKeys.Summary]: { name: 'summary' },
        [OutlinePageKeys.Generalize]: { name: 'universal message' },
    }

    const { register, handleSubmit, setValue, watch, formState: { errors, isSubmitSuccessful } } = useForm()
    const [previewFont, setPreviewFont] = useState('')
    const [feedbackData, setFeedbackData] = useState(null)
    const [evidenceBoxesFilled, setEvidenceBoxesFilled] = useState(true)
    const [showCongrats, setShowCongrats] = useState(false)
    const { state, actions } = useStateMachine({ updateToolboxOutlinePageData, updateToolboxOptions })
    const shouldAutofill = useRef(true)

    useEffect(() => {
        if (shouldAutofill.current) {
            for (const [label, val] of Object.entries(state[ToolboxStoreKeys.ToolboxOutlinePage])) {
                if (val)
                    setValue(label, val)
            }
            const { thesis, link } = state[ToolboxStoreKeys.ToolboxThesisPage]
            const { tool1, tool2, tool3, mainIdea } = state[ToolboxStoreKeys.ToolboxPage]

            const thesisTemplate = thesis

            const topic1Template = `First, the author employs ${tool1} to express ${link ? link + ' ' : ''}${mainIdea}.`
            const topic2Template = `Additionally, the author uses ${tool2} to communicate ${link ? link + ' ' : ''}${mainIdea}.`
            const topic3Template = `Finally, the author employs ${tool3} to show ${link ? link + ' ' : ''}${mainIdea}.`
            const restateTemplate = `Thus, the author uses ${tool1}, ${tool2}, ${tool3} to reveal ${mainIdea}.`

            // if (!state[StoreKeys.OutlinePageKey][OutlinePageKeys.Thesis])
            setValue('thesis', thesisTemplate)
            // if (!state[StoreKeys.OutlinePageKey][OutlinePageKeys.Topic1])
            setValue('topic1', topic1Template)
            // if (!state[StoreKeys.OutlinePageKey][OutlinePageKeys.Topic2])
            setValue('topic2', topic2Template)
            // if (!state[StoreKeys.OutlinePageKey][OutlinePageKeys.Topic3])
            setValue('topic3', topic3Template)
            // if (!state[StoreKeys.OutlinePageKey][OutlinePageKeys.RestateThesis])
            setValue('restateThesis', restateTemplate)
            shouldAutofill.current = false
        }
    }, [state, setValue])

    const onSubmit = data => {
        // console.log(data)

        setEvidenceBoxesFilled(true)
        if (state[ToolboxStoreKeys.ToolboxOptions][OptionsKeys.Hook] === -1) {
            toast.error(`The hook box has not been filled in!`, {
                position: "bottom-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setEvidenceBoxesFilled(false)
            return
        }

        for (const [key, value] of Object.entries(state[ToolboxStoreKeys.ToolboxOptions])) {
            if (key === OptionsKeys.Hook)
                continue
            if (value === -1) {
                toast.error(`Not all evidence boxes have been filled in!`, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setEvidenceBoxesFilled(false)
                return
            } else if (value !== 0) {
                data[key] = Object.values(QuoteKeySuffixes).map(([suffix, action, placeholder]) => state[ToolboxStoreKeys.ToolboxOutlinePage][key + suffix]).join(' ')
                // console.log(data[key])
            }
        }

        // console.log(data)

        axios.post(`/validate/conclusions`, { data })
            .then((res) => {
                // console.log(res)
                setFeedbackData(res.data)
                if (res.data.suggestions) {
                    for (const [label, suggestion] of Object.entries(res.data.feedback)) {
                        if (suggestion) {
                            toast.error(`In your ${labelProps[label].name}, ${suggestion}`, {
                                position: "bottom-right",
                                autoClose: false,
                                hideProgressBar: true,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }
                    return
                } else {
                    actions.updateToolboxOutlinePageData(data)
                }
            }).catch((e) => {
                console.error(e)
            })

        if (evidenceBoxesFilled) {
            setShowCongrats(true)
        }
    }

    const lastFormData = useRef(null)
    const currFormData = watch()

    useEffect(() => {
        if (!isEqual(lastFormData.current, currFormData)) {
            // console.log('saving after 2000ms?')
            actions.updateToolboxOutlinePageData(currFormData)
            lastFormData.current = currFormData
        }
    }, [actions, currFormData])

    const generatePreviewText = () => {
        if (currFormData && !isEmpty(currFormData)) {
            const elements = Object.entries(groups).map(([key, paragraph], index) => (
                <p key={key} style={{ backgroundColor: previewBgColors[index], textIndent: '1rem', lineHeight: '2' }} className="p-3 rounded">{paragraph.map((label) => {
                    if (label !== 'hook' && Object.values(OptionsKeys).includes(label) && state[ToolboxStoreKeys.ToolboxOptions][label] !== -1 && state[ToolboxStoreKeys.ToolboxOptions][label] !== 0)
                        return Object.values(QuoteKeySuffixes).map(([suffix, action, placeholder]) => {
                            if ((label + suffix) in state[ToolboxStoreKeys.ToolboxOutlinePage])
                                return state[ToolboxStoreKeys.ToolboxOutlinePage][label + suffix]
                            return ''
                        }).join(' ')

                    if (label in currFormData && currFormData[label])
                        return currFormData[label].trim()
                    return ''
                }).join(' ')}</p>
            ))
            return elements
        }
        return ''
    }

    const areBoxesFilled = () => {
        if (currFormData && !isEmpty(currFormData)) {
            for (const val of Object.values(currFormData)) {
                if (!val) {
                    return false
                }
            }
            return true
        }
        return false
    }

    const conclusion1Feedback = feedbackData?.feedback['conclusion1']
    const conclusion2Feedback = feedbackData?.feedback['conclusion2']
    const conclusion3Feedback = feedbackData?.feedback['conclusion3']

    const currHookOption = state[ToolboxStoreKeys.ToolboxOptions][OptionsKeys.Hook] === -1 ? null : hookOptions[state[ToolboxStoreKeys.ToolboxOptions][OptionsKeys.Hook]][0]
    const currExampleHook = state[ToolboxStoreKeys.ToolboxOptions][OptionsKeys.Hook] === -1 ? null : hookOptions[state[ToolboxStoreKeys.ToolboxOptions][OptionsKeys.Hook]][2]

    const currEvidenceOptions = [1, 2, 3, 4, 5, 6].map((num) => evidenceOptions[1][0])

    return (
        <div className="five-paragraph-template-wrapper">
            <form id="fiveParagraphTemplateForm" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <h5>Paragraph 1: Introduction</h5>

                {currHookOption ? (<div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.hook ? 'is-invalid' : ''}`} placeholder={`Hook (${currHookOption})`} aria-describedby="hookTextHelp" {...register('hook', { required: true })} />
                    <div id="hookTextHelp" className="form-text">Convince people to read your essay! (<i>{currExampleHook}</i>)</div>
                    <div>
                        <LinkStyleButton onClick={() => {
                            actions.updateToolboxOptions({ [OptionsKeys.Hook]: -1 })
                        }}>Change Hook Option</LinkStyleButton>
                    </div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>) : <HookOptions handleChoose={(index) => {
                    actions.updateToolboxOptions({ [OptionsKeys.Hook]: index })
                }} />}
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.background ? 'is-invalid' : ''}`} placeholder="Background Info"
                        aria-describedby="backgroundInfoTextHelp" {...register('background', { required: true })} />
                    <div id="backgroundInfoTextHelp" className="form-text">Provide context on your topic.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.thesis ? 'is-invalid' : ''}`} placeholder="Thesis Statement"
                        aria-describedby="thesisTextHelp" {...register('thesis', { required: true })} />
                    <div id="thesisTextHelp" className="form-text">What is the point of your essay? Also make sure to
                        preview
                        the
                        main ideas in your body paragraphs.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <h5>Paragraph 2: Body Paragraph 1</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.topic1 ? 'is-invalid' : ''}`} placeholder="Topic Sentence"
                        aria-describedby="topicTextHelp1" {...register('topic1', { required: true })} />
                    <div id="topicTextHelp1" className="form-text">Introduce the role of tool #1.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[0]} evidenceKey={OptionsKeys.Evidence1} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.transition1 ? 'is-invalid' : ''}`} placeholder="Transition"
                        aria-describedby="transitionTextHelp1" {...register('transition1', { required: true })} />
                    <div id="transitionTextHelp1" className="form-text">Add a transition word or sentence to connect the first and second pieces of evidence together.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[1]} evidenceKey={OptionsKeys.Evidence2} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${(errors.conclusion1 || conclusion1Feedback) ? 'is-invalid' : ''}`} placeholder="Concluding Sentence"
                        aria-describedby="concludingTextHelp1" {...register('conclusion1', { required: true })} />
                    <div id="concludingTextHelp1" className="form-text">Summarize the role of tool #1 (rephrase topic
                        sentence).
                    </div>
                    {!conclusion1Feedback && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>

                <h5>Paragraph 3: Body Paragraph 2</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.topic2 ? 'is-invalid' : ''}`} placeholder="Topic Sentence"
                        aria-describedby="topicTextHelp2" {...register('topic2', { required: true })} />
                    <div id="topicTextHelp2" className="form-text">Introduce the role of tool #2.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[2]} evidenceKey={OptionsKeys.Evidence3} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.transition2 ? 'is-invalid' : ''}`} placeholder="Transition"
                        aria-describedby="transitionTextHelp2" {...register('transition2', { required: true })} />
                    <div id="transitionTextHelp2" className="form-text">Add a transition word or sentence to connect the first and second pieces of evidence together.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[3]} evidenceKey={OptionsKeys.Evidence4} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${(errors.conclusion2 || conclusion2Feedback) ? 'is-invalid' : ''}`} placeholder="Concluding Sentence"
                        aria-describedby="concludingTextHelp2" {...register('conclusion2', { required: true })} />
                    <div id="concludingTextHelp2" className="form-text">Summarize the role of tool #2 (rephrase topic
                        sentence).
                    </div>
                    {!conclusion2Feedback && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>

                <h5>Paragraph 4: Body Paragraph 3</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.topic3 ? 'is-invalid' : ''}`} placeholder="Topic Sentence"
                        aria-describedby="topicTextHelp3" {...register('topic3', { required: true })} />
                    <div id="topicTextHelp3" className="form-text">Introduce the role of tool #3.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[4]} evidenceKey={OptionsKeys.Evidence5} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.transition3 ? 'is-invalid' : ''}`} placeholder="Transition"
                        aria-describedby="transitionTextHelp3" {...register('transition3', { required: true })} />
                    <div id="transitionTextHelp3" className="form-text">Add a transition word or sentence to connect the first and second pieces of evidence together.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>

                <EvidenceContainer currEvidenceOption={currEvidenceOptions[5]} evidenceKey={OptionsKeys.Evidence6} actions={actions} errors={errors} register={register} />

                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${(errors.conclusion3 || conclusion3Feedback) ? 'is-invalid' : ''}`} placeholder="Concluding Sentence"
                        aria-describedby="concludingTextHelp3" {...register('conclusion3', { required: true })} />
                    <div id="concludingTextHelp3" className="form-text">Summarize the role of tool #3 (rephrase topic
                        sentence).
                    </div>
                    {!conclusion3Feedback && <div className="invalid-feedback">Hey! You need to fill this in!</div>}
                </div>

                <h5>Paragraph 5: Conclusion</h5>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.restateThesis ? 'is-invalid' : ''}`} placeholder="Restate Thesis"
                        aria-describedby="restateThesisTextHelp" {...register('restateThesis', { required: true })} />
                    <div id="restateThesisTextHelp" className="form-text">Restate the thesis statement.
                    </div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.summary ? 'is-invalid' : ''}`} placeholder="Summary"
                        aria-describedby="summaryTextHelp" {...register('summary', { required: true })} />
                    <div id="summaryTextHelp" className="form-text">Connect all 3 of your topic sentences together.
                    </div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>
                <div className="mb-3">
                    <TextareaAutosize minRows={3} className={`form-control ${errors.generalize ? 'is-invalid' : ''}`}
                        placeholder="Generalize to universal message" aria-describedby="generalizeTextHelp"
                        {...register('generalize', { required: true })} />
                    <div id="generalizeTextHelp" className="form-text">Why does your essay matter to readers in the big
                        picture?
                        End with a universal message.</div>
                    <div className="invalid-feedback">Hey! You need to fill this in!</div>
                </div>
                <ActionWrapper>
                    <Button variant="primary" onClick={previous}>Let me see my thesis
                        again!</Button>
                    <Button type="submit" variant="primary">Submit</Button>
                </ActionWrapper>
            </form>
            <div className="mt-3">
                {isSubmitSuccessful && evidenceBoxesFilled && generatePreviewText()}
            </div>
            <Modal size="lg" show={showPreview} onHide={handlePreviewClose}>
                <Modal.Header>
                    <StyledNavbar expand="lg">
                        <Modal.Title>Preview your essay</Modal.Title>
                        <Nav className="ml-auto">
                            <NavDropdown className="right-aligned-dropdown" title={previewFont || 'Font'} id="font-selector" style={{ fontFamily: previewFont }}>
                                {fonts.map((font, index) => <NavDropdown.Item key={index} eventKey={font} active={font === previewFont} onSelect={(eventKey) => { setPreviewFont(eventKey) }} href="#" style={{ fontFamily: font }}>{font}</NavDropdown.Item>)}
                            </NavDropdown>
                        </Nav>
                    </StyledNavbar>
                </Modal.Header>
                <Modal.Body style={{ fontFamily: previewFont }}>
                    {generatePreviewText()}
                    {!areBoxesFilled() && <Badge variant="warning">Just a heads-up, not all boxes are filled in!</Badge>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePreviewClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCongrats} onHide={() => { setShowCongrats(false) }} centered>
                <Modal.Body>
                    Congrats on writing your essay!🎉 Don’t forget to add a works cited page if you used outside sources.
                </Modal.Body>
                <Modal.Footer style={{ borderStyle: 'none' }}>
                    <Button variant="secondary" onClick={() => { setShowCongrats(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Confetti shouldFire={showCongrats} />
        </div>
    )
}

export default ToolboxOutlinePage