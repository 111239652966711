import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import logo from '../assets/icanwritelogo.png'
import fiveParagraphLogo from '../assets/5paragraphs.png'
import toolboxLogo from '../assets/toolboxicon.png'

const Logo = styled.img`
    width: 100%;
    height: auto;
`

const TemplateSection = styled.div`
    margin-top: 3rem;
    margin-bottom: 1rem;
`

const TemplateContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const TemplateItem = styled.a`
    padding: 1.5rem;
    text-decoration: none;
    text-align: center;
    
    border-radius: .25rem !important;
    transition: box-shadow 0.35s ease-in-out;

    &:hover {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
        text-decoration: none;
    }

    & img {
        width: 10rem;
        height: auto;
        margin-bottom: 1.25rem;
    }
`

const Home = () => {
    return (
        <div style={{ fontFamily: 'Markup' }}>
            <Navbar expand="lg" sticky="top" variant="light" bg="light">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link href="/" active>Templates</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container>
                <div>
                    <Logo src={logo} alt="I Can Write! logo" />
                </div>
                <TemplateSection>
                    <h3 style={{ textAlign: 'center' }}>Writing Templates</h3>
                    <TemplateContainer>
                        <TemplateItem href='/5-paragraph-template'>
                            <img src={fiveParagraphLogo} alt="Five Paragraph Essay Template" />
                            <h6>5-Paragraph Essay</h6>
                        </TemplateItem>
                        <TemplateItem href='/toolbox'>
                            <img src={toolboxLogo} alt="Toolbox Template" />
                            <h6>Toolbox</h6>
                        </TemplateItem>
                    </TemplateContainer>
                </TemplateSection>
            </Container>
        </div>
    )
}

export default Home