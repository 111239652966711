import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import About from './components/About'
import DangerZone from './components/DangerZone'
import Home from './components/Home'
import MasterForm from './components/MasterForm'
import { withPhoneWarning } from './components/PhoneWarning'
import ToolboxMasterForm from './components/ToolboxMasterForm'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/5-paragraph-template' component={withPhoneWarning(MasterForm)} />
        <Route path='/danger-zone'>
          <DangerZone />
        </Route>
        <Route path='/toolbox' component={withPhoneWarning(ToolboxMasterForm)} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
