import { createStore, StateMachineProvider } from 'little-state-machine'
import React, { useState } from 'react'
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { initialState, toolboxInitialState } from '../actions'
import logo from '../assets/icanwritelogo.png'
import { fontColors, fonts, themes } from '../constants'
import Toolbox from './Toolbox'
import ToolboxOutlinePage from './ToolboxOutlinePage'
import ToolboxThesisPage from './ToolboxThesisPage'

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${props => props.theme} no-repeat fixed;

    & form, & form .form-control, & ::placeholder {
        color: ${props => props.color};
    }
`

const ThemeThumbnail = styled.div`
    height: 2em;
    width: 100%;
    margin: 0 auto;
    background: ${props => props.gradient};
    ${props => props.gradient === 'white' ? 'border: 0.5px solid black' : ''}
`

const FontColorContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
`

const FontColorThumbnail = styled.div`
    height: 2em;
    width: 2em;
    margin: 0 auto;
    background-color: ${props => props.bgColor};
    ${props => props.bgColor === '#fff' ? 'border: 0.5px solid black' : ''}
`

createStore({
    ...toolboxInitialState,
    ...initialState
},
    {
        storageType: localStorage
    })

const ToolboxMasterForm = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const [currFont, setCurrFont] = useState('')
    const [currFontColor, setCurrFontColor] = useState('#000000')
    const [currTheme, setCurrTheme] = useState(themes[0])

    const [showPreviewModal, setShowPreviewModal] = useState(false)

    const _next = () => {
        setCurrentStep(currentStep >= 2 ? 3 : currentStep + 1)
    }

    const _prev = () => {
        setCurrentStep(currentStep <= 1 ? 1 : currentStep - 1)
    }

    const renderSteps = (currStep) => {
        if (currStep === 1) {
            return <Toolbox next={_next} />
        } else if (currStep === 2) {
            return <ToolboxThesisPage previous={_prev} next={_next} />
        } else {
            return <ToolboxOutlinePage previous={_prev} showPreview={showPreviewModal} handlePreviewClose={() => { setShowPreviewModal(false) }} />
        }
    }

    return (
        <StateMachineProvider>
            <AppContainer theme={currTheme} color={currFontColor}>
                <Navbar bg={currTheme !== 'black' ? 'light' : ''} expand="lg" sticky="top" variant={currTheme === 'black' ? 'dark' : 'light'}>
                    <Navbar.Brand href='/'><img src={logo} width="100" height="60" alt="I Can Write logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <NavDropdown title="Theme" id="theme-selector">
                                {themes.map((theme, index) => <NavDropdown.Item
                                    eventKey={theme}
                                    active={theme === currTheme}
                                    onSelect={(eventKey) => setCurrTheme(eventKey)}
                                    href="#"
                                    key={index}><ThemeThumbnail
                                        className="rounded"
                                        gradient={theme}
                                    /></NavDropdown.Item>)}
                            </NavDropdown>
                            <NavDropdown className="right-aligned-dropdown" title="Font" id="font-selector">
                                {fonts.map((font, index) => <NavDropdown.Item
                                    key={index}
                                    eventKey={font}
                                    active={font === currFont}
                                    onSelect={(eventKey) => setCurrFont(eventKey)}
                                    href="#"
                                    style={{ fontFamily: font }}
                                >{font}</NavDropdown.Item>)}
                            </NavDropdown>
                            <NavDropdown className="right-aligned-dropdown" title="Font Color" id="font-color-selector">
                                <FontColorContainer>
                                    {fontColors.map((fontColor, index) => <NavDropdown.Item
                                        key={index}
                                        eventKey={fontColor}
                                        active={fontColor === currFontColor}
                                        onSelect={(eventKey) => setCurrFontColor(eventKey)}
                                        href="#"
                                    ><FontColorThumbnail bgColor={fontColor} className="rounded" /></NavDropdown.Item>)}
                                </FontColorContainer>
                            </NavDropdown>
                            <Button variant="primary" disabled={currentStep !== 3} onClick={() => { setShowPreviewModal(true) }}>Preview</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="container-fluid p-3 h-100" style={{ fontFamily: currFont, color: currFontColor, overflowY: 'auto' }}>
                    <Container color={currFontColor}>
                        {renderSteps(currentStep)}
                    </Container>
                </div>
            </AppContainer>
        </StateMachineProvider>
    )
}

export default ToolboxMasterForm