import React from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'

const DangerZone = () => {
    return (
        <div style={{ fontFamily: 'Markup' }}>
            <Navbar expand="lg" sticky="top" variant="light" bg="light">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link href="/">Templates</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container className="py-3 text-center">
                <Button variant="danger" onClick={() => { localStorage.clear() }}>Clear site data</Button>
            </Container>
        </div>
    )
}

export default DangerZone